* {
  font-family: "Figtree", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
  -webkit-tap-highlight-color: transparent !important;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  font-family: "Figtree", sans-serif;
}
